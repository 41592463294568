import moment from 'moment';
import {
  chain,
  filter,
  find,
  forEach,
  indexOf,
  isEmpty,
  reduce,
  reject
} from 'lodash';
import {
  FLAG_DOCTOR_NOT_LISTED,
  FLAG_UNSURE_WHICH_DOCTOR
} from '_cdss/library/constants/flags';
import { DEFAULT_DESTINATION_STORES } from '_cdss/redux/constants';
import {
  QUESTION_ID_PATIENT_DOES_NOT_HAVE_ASTHMA,
  QUESTION_ID_PATIENT_IS_PREGNANT,
  QUESTION_ID_PATIENT_NOT_SUITABLE,
  QUESTION_ID_AAP_DEFERED,
  QUESTION_ID_AAP_CONTINUED,
  QUESTION_ID_AAP_APPROVED,
  QUESTION_ID_AAP_PRINTED,
  QUESTION_ID_AAP_COMPLETED,
  QUESTION_ID_PREPEND_CHART_NOTE_MESSAGES,
  QUESTION_ID_QUESTIONNAIRE_COMPLETED,
  SURVEY_LINK_UUID_CONTROL_QUESTION_1,
  SURVEY_LINK_UUID_CONTROL_QUESTION_2,
  SURVEY_LINK_UUID_CONTROL_QUESTION_3,
  SURVEY_LINK_UUID_CONTROL_QUESTION_4,
  SURVEY_LINK_UUID_CONTROL_QUESTION_5,
  SURVEY_LINK_UUID_CONTROL_QUESTION_6,
  OPTION_UUID_YES,
  OPTION_UUID_NO
} from '_cdss/constants/surveys';
import isUnderGoodControlFunction from '_cdss/library/businessLogic/patientProfile/isUnderGoodControl';
import isDestinationStoreEmpty from '_cdss/library/businessLogic/isDestinationStoreEmpty';

import checkArraySizeGreaterThan from './checkArraySizeGreaterThan';

export const modelQuestionnaireResponses = (props = {}) => {
  const { surveyOptions = [], surveyResponses = [] } = props;
  const contactInformation = chain({
    // First name
    firstName: '4887C22D-E5A4-4A43-958C-1EF26339A0FD',

    // Last name
    lastName: 'E43E8E45-E0C0-44E3-A1A4-A659D4240E65',

    // DOB
    dateOfBirth: '51A76F9C-D2BC-4DD5-BDB7-EA2DBB3D67FF',

    // Emergency contact
    emergencyContact: '82D8F1D4-F49B-48FF-9B1E-3345C6D446DE',

    // Emergency phone number
    emergencyContactNumber: 'A9A8FE43-397D-47BB-B576-BABC26F649DB',

    // Email
    email: '8C03D5E3-3C0F-45BF-9207-4AD86D2F888F'
  })
    .reduce(
      (responses, surveyLinkUUID, key) =>
        chain(surveyResponses)
          .filter(response => response.surveyLinkUUID === surveyLinkUUID)
          .reduce((_responses, response) => {
            const { optionUUID, responseDateTime, responseString } = response;

            if (optionUUID) {
              return chain(surveyOptions)
                .filter({ optionUUID })
                .head()
                .thru(option => option.optionText.toLowerCase())
                .thru(value => Object.assign({}, responses, { [key]: value }))
                .value();
            }

            if (responseDateTime) {
              return Object.assign({}, responses, {
                [key]: moment(responseDateTime).format('YYYY-MM-DD')
              });
            }

            return Object.assign({}, responses, {
              [key]: responseString.toLowerCase()
            });
          }, responses)
          .value(),
      {}
    )
    .value();

  const triggerResponses = chain(surveyResponses)
    // Choose all the triggers that make your asthma symptoms worse. If none apply, choose "None of the above".
    .filter({ surveyLinkUUID: 'FE5C564C-C2EF-461D-BDDB-6E3F803797A5' })
    .map('optionUUID')
    .reduce(
      (responses, optionUUID) =>
        chain(surveyOptions)
          .filter({ optionUUID })
          .map('optionText')
          .thru(optionText => {
            if (
              chain(optionText)
                .head()
                .toLower()
                .value() === 'other'
            ) {
              return (
                chain(surveyResponses)
                  // "Other"
                  .filter({
                    surveyLinkUUID: 'E3D5F934-FFC7-4415-9A70-DEC4D795AC34'
                  })
                  .map('responseText')
                  .head()
                  .value()
              );
            }

            return optionText;
          })
          .concat(responses)
          .value(),
      []
    )
    .value();

  const peakFlowResponse = chain(surveyResponses)
    // What is your personal best peak flow? If you are not sure, leave this blank
    .filter({ surveyLinkUUID: '4B95E544-372F-493C-ACAB-046392F8893A' })
    .map('responseNumeric')
    .head()
    .value();

  const usePeakFlow = chain(surveyResponses)
    // Do you use a peak flow meter?
    .filter({ surveyLinkUUID: '63BA4ACE-E720-4746-87DB-D0BD2A37FC5B' })
    .map('responseNumeric')
    .head()
    .thru(value => {
      if (value === 1) {
        return 'yes';
      }

      if (value === 0) {
        return 'no';
      }

      if (value === 3) {
        return 'unsure';
      }

      return '';
    })
    .value();

  const coldResponse = chain(surveyResponses)
    // Does your asthma flare when you have a cold?
    .filter({ surveyLinkUUID: 'EE8878A1-12B0-43A2-9D53-4B497FC12840' })
    .map('responseNumeric')
    .thru(value => value.toString() !== '0' && !isEmpty(value))
    .value();

  const allergiesResponse = chain(surveyResponses)
    // Do you have allergies?
    .filter({ surveyLinkUUID: 'BBF4D2CA-4923-44AC-9BDF-FA12B9EF5993' })
    .map('responseNumeric')
    .head()
    .thru(value => {
      if (value === 1) {
        return 'yes';
      }

      if (value === 0) {
        return 'no';
      }

      if (value === 3) {
        return 'unsure';
      }

      return '';
    })
    .value();

  const symptomsResponses = chain(surveyResponses)
    // Which of the following symptoms have you experienced as a result of your asthma?
    .filter({ surveyLinkUUID: '3670872B-518B-4054-912B-678ACFC694ED' })
    .map('optionUUID')
    .reduce(
      (responses, optionUUID) =>
        chain(surveyOptions)
          .filter({ optionUUID })
          .map('optionText')
          .thru(optionText => {
            if (
              chain(optionText)
                .head()
                .toLower()
                .value() === 'other'
            ) {
              return (
                chain(surveyResponses)
                  // "Other"
                  .filter({
                    surveyLinkUUID: '2E9E1A58-48D6-4632-9957-2DB1C1BA2AF1'
                  })
                  .map('responseText')
                  .head()
                  .value()
              );
            }

            return optionText;
          })
          .concat(responses)
          .value(),
      []
    )
    .value();

  const physicalLimitationResponse = chain(surveyResponses)
    // Do you ever have limitations in your physical activities or sports when asthma is flaring?
    .filter({ surveyLinkUUID: 'AB1710FA-FD01-4228-B181-5289B1B4B10B' })
    .map('optionUUID')
    .reduce(
      (responses, optionUUID) =>
        chain(surveyOptions)
          .filter({ optionUUID })
          .map('optionText')
          .concat(responses)
          .value(),
      []
    )
    .head()
    .value();

  const physicalActivitiesLimitedResponses = chain(surveyResponses)
    // Which physical activities may become limited when asthma is flaring?
    .filter({ surveyLinkUUID: 'A77B3D45-B81F-4A47-A9AD-3BFBE7DF3FCE' })
    .map('optionUUID')
    .reduce(
      (responses, optionUUID) =>
        chain(surveyOptions)
          .filter({ optionUUID })
          .map('optionText')
          .thru(optionText => {
            if (
              chain(optionText)
                .head()
                .toLower()
                .value() === 'other'
            ) {
              return (
                chain(surveyResponses)
                  // "Other"
                  .filter({
                    surveyLinkUUID: '11CF168C-782B-4981-815B-004535D3493B'
                  })
                  .map('responseText')
                  .head()
                  .value()
              );
            }

            return optionText;
          })
          .concat(responses)
          .value(),
      []
    )
    .value();

  const sports = chain(surveyResponses)
    // Which sports do you partake in that become limited when asthma is flaring?
    .filter({ surveyLinkUUID: '5DD52623-A69E-4EB9-B3C6-99D75E55EB7D' })
    .map('optionUUID')
    .reduce(
      (responses, optionUUID) =>
        chain(surveyOptions)
          .filter({ optionUUID })
          .map('optionText')
          .thru(optionText => {
            if (
              chain(optionText)
                .head()
                .toLower()
                .value() === 'other'
            ) {
              return (
                chain(surveyResponses)
                  // "Other"
                  .filter({
                    surveyLinkUUID: '667E356B-BE1F-49B9-B636-CA0875CC8594'
                  })
                  .map('responseText')
                  .head()
                  .value()
              );
            }

            return optionText;
          })
          .concat(responses)
          .value(),
      []
    )
    .value();

  const inSchool = chain(surveyResponses)
    // Are you currently in school?
    .filter({ surveyLinkUUID: 'C1D9C927-477A-4066-9D38-D6BD3A2DA7A0' })
    .map('responseNumeric')
    .head()
    .thru(value => value !== 0)
    .value();

  const currentlyWorking = chain(surveyResponses)
    // Are you currently working?
    .filter({ surveyLinkUUID: 'E8A3DEF3-1EB5-4869-BD9E-BF6FC664EDD7' })
    .map('responseNumeric')
    .head()
    .thru(value => value !== 0)
    .value();

  const isTakingMedications = chain(surveyResponses)
    // Do you take puffers or asthma medications (either regularly or occasionnally?
    .filter({ surveyLinkUUID: '05D4254D-88AE-4AAD-B254-71B4039E0DC2' })
    .map('responseNumeric')
    .head()
    .thru(value => {
      if (value === 1) {
        return 'yes';
      }

      if (value === 0) {
        return 'no';
      }

      if (value === 3) {
        return 'unsure';
      }

      return '';
    })
    .value();

  const controlSurveyResponses = filter(
    surveyResponses,
    ({ surveyLinkUUID }) =>
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_1 ||
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_2 ||
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_3 ||
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_4 ||
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_5 ||
      surveyLinkUUID === SURVEY_LINK_UUID_CONTROL_QUESTION_6
  );

  const controlResponses = reduce(
    controlSurveyResponses,
    (responses, response) => {
      const { optionUUID, questionId, responseString } = response;

      if (optionUUID) {
        return chain(surveyOptions)
          .find({ optionUUID })
          .get('optionText')
          .thru((optionText = '') =>
            Object.assign(responses, {
              [questionId]: optionText.toLowerCase()
            })
          )
          .value();
      }

      return Object.assign(responses, {
        [questionId]: responseString.toLowerCase()
      });
    },
    {}
  );

  const rescuePufferFrequency = indexOf(
    [
      'none (rarely)',
      'about 1 time per week',
      'about 2 times per week',
      'about 3 times per week',
      '4 or more times per week'
    ],
    controlResponses.rescue
  );

  const asthmaFrequency = indexOf(
    ['less than 1', 'about 1', 'about 2', 'about 3', '4 or more'],
    controlResponses.symptoms
  );

  const modeledResponses = {
    asthmaFrequency,
    controlResponses,
    controlSurveyResponses,
    triggerResponses,
    symptomsResponses,
    physicalActivitiesLimitedResponses,
    physicalLimitationResponse,
    peakFlowResponse,
    isTakingMedications,
    usePeakFlow,
    coldResponse,
    allergiesResponse,
    sports,
    inSchool,
    currentlyWorking,
    dateOfBirth: contactInformation.dateOfBirth,
    email: contactInformation.email,
    emergencyContact: contactInformation.emergencyContact,
    emergencyPhoneNumber: contactInformation.emergencyContactNumber,
    rescuePufferFrequency
  };

  return modeledResponses;
};

const getQuestionnaireCompleted = (props = {}) => {
  const { surveyResponses = {} } = props;

  const questionnaireCompleted =
    chain(surveyResponses)
      .find({ questionId: QUESTION_ID_QUESTIONNAIRE_COMPLETED })
      .get('responseState')
      .value() || false;

  return questionnaireCompleted;
};

const getQuestionnaireCompletedDateTime = (props = {}) => {
  const { surveyResponses = {} } = props;

  const questionnaireCompletedDateTime = chain(surveyResponses)
    .find({ questionId: QUESTION_ID_QUESTIONNAIRE_COMPLETED })
    .get('recordCreateDateTime')
    .thru(date => {
      if (!date) {
        return null;
      }

      return new Date(date).getTime();
    })
    .value();

  return questionnaireCompletedDateTime;
};

export const getCDSSStatus = (props = {}) => {
  const { instanceLink = {} } = props;

  return {
    doctorNotListed: instanceLink[`flag${FLAG_DOCTOR_NOT_LISTED}`],
    unsureWhichDoctor: instanceLink[`flag${FLAG_UNSURE_WHICH_DOCTOR}`]
  };
};

export const getCDSSStatusResponses = (props = {}) => {
  const { allMedicationsStatus, surveyResponses = [] } = props;

  // const medsStatusQuestionId = 'meds_status';
  const screenQuestionId = 'screen';

  const questionIdsBoolean = {
    patientDoesNotHaveAsthma: QUESTION_ID_PATIENT_DOES_NOT_HAVE_ASTHMA,
    patientIsPregnant: QUESTION_ID_PATIENT_IS_PREGNANT,
    patientNotSuitableForAAP: QUESTION_ID_PATIENT_NOT_SUITABLE,
    aapDefered: QUESTION_ID_AAP_DEFERED,
    aapContinued: QUESTION_ID_AAP_CONTINUED,
    aapApproved: QUESTION_ID_AAP_APPROVED,
    aapPrinted: QUESTION_ID_AAP_PRINTED,
    aapCompleted: QUESTION_ID_AAP_COMPLETED
  };

  const medsStatuses = reduce(
    allMedicationsStatus,
    (acc, curr, key) =>
      Object.assign({}, acc, {
        [key]: curr === 2 ? 'Submitted' : 'Draft'
      }),
    {}
  );

  const screen =
    chain(surveyResponses)
      .find({
        questionId: screenQuestionId,
        responseSetId: 'cdss'
      })
      .get('responseString')
      .value() || '';

  const booleanKeyValues = reduce(
    questionIdsBoolean,
    (acc, curr, key) => {
      const response =
        find(surveyResponses, {
          questionId: curr
        }) || {};

      if (curr === questionIdsBoolean.aapApproved) {
        return Object.assign({}, acc, {
          [key]: response.responseState || false,
          aapApprovedDateTime: response.recordCreateDateTime
        });
      }

      if (curr === questionIdsBoolean.aapPrinted) {
        return Object.assign({}, acc, {
          [key]: response.responseState || false,
          aapPrintedDateTime: response.recordCreateDateTime
        });
      }

      return Object.assign({}, acc, {
        [key]: response.responseState || false
      });
    },
    {}
  );

  return {
    ...booleanKeyValues,
    medsStatuses,
    screen
  };
};

const getPatientProfileMedicationCalculatedProperties = (props = {}) => {
  const { controlResponses, medications } = props;

  const isOnMedications = checkArraySizeGreaterThan({
    array: reject(
      medications,
      ({ functionType }) => typeof functionType === 'undefined'
    ),
    size: 0
  });

  const isOnControllerMedications = checkArraySizeGreaterThan({
    array: filter(medications, {
      functionType: 'controller'
    }),
    size: 0
  });

  const isOnInhalerMedications = checkArraySizeGreaterThan({
    array: filter(medications, { functionType: 'reliever' }),
    size: 0
  });

  const isOnControllerMedicationsOnly =
    isOnControllerMedications && !isOnInhalerMedications;
  const isOnInhalerMedicationsOnly =
    isOnInhalerMedications && !isOnControllerMedications;

  const isUnderGoodControl = isUnderGoodControlFunction({
    controlResponses,
    isOnControllerMedications,
    isOnInhalerMedicationsOnly,
    isOnMedications
  });

  return {
    isOnControllerMedications,
    isOnControllerMedicationsOnly,
    isOnInhalerMedications,
    isOnInhalerMedicationsOnly,
    isOnMedications,
    isUnderGoodControl
  };
};

export const updatePatientProfileMedications = (props = {}) => {
  const { medications, patientProfile = {} } = props;

  const medicationCalculatedProperties =
    getPatientProfileMedicationCalculatedProperties({
      controlResponses: patientProfile.controlResponses,
      medications
    }) || {};

  return Object.assign({}, patientProfile, {
    ...medicationCalculatedProperties,
    medications
  });
};

const isControlQuestionsCompleted = (props = {}) => {
  const { controlResponses = {} } = props;

  const keys = Object.keys(controlResponses);
  const filledOut = keys.includes('flare')
    ? keys.length === 5
    : keys.length === 6;

  return filledOut;
};

const getLastControlQuestionsCompletedDateTime = (props = {}) => {
  const { controlSurveyResponses = [] } = props;
  const uuids = [
    SURVEY_LINK_UUID_CONTROL_QUESTION_1,
    SURVEY_LINK_UUID_CONTROL_QUESTION_2,
    SURVEY_LINK_UUID_CONTROL_QUESTION_3,
    SURVEY_LINK_UUID_CONTROL_QUESTION_4,
    SURVEY_LINK_UUID_CONTROL_QUESTION_5,
    SURVEY_LINK_UUID_CONTROL_QUESTION_6
  ];
  if (controlSurveyResponses && controlSurveyResponses.length > 0) {
    const surveyLinkUUID = uuids[controlSurveyResponses.length - 1];
    const lastControlQuestion = controlSurveyResponses.find(
      controlResponse => controlResponse.surveyLinkUUID === surveyLinkUUID
    );

    if (lastControlQuestion) {
      return new Date(lastControlQuestion.recordChangeDateTime).getTime();
    }
  }

  return null;
};

const getPrependChartNoteMessagesResponse = (props = {}) => {
  const { surveyResponses = [] } = props;

  const prependChartNoteMessagesResponse = find(surveyResponses, {
    questionId: QUESTION_ID_PREPEND_CHART_NOTE_MESSAGES
  });

  return prependChartNoteMessagesResponse;
};

const getAllMedications = ({ medicationsStore }) => {
  const {
    doctorRecommendations,
    original,
    rulesRecommendations,
    rulesRecommendationsMedicationOptions,
    stepUpRecommendations
  } = medicationsStore;

  return {
    doctorRecommendations,
    original,
    rulesRecommendations,
    rulesRecommendationsMedicationOptions,
    stepUpRecommendations
  };
};
const getAllMedicationsStatus = ({ medicationsStore }) => {
  const {
    doctorRecommendationsStatus,
    originalStatus,
    rulesRecommendationsStatus,
    stepUpRecommendationsStatus
  } = medicationsStore;

  return {
    doctorRecommendationsStatus,
    originalStatus,
    rulesRecommendationsStatus,
    stepUpRecommendationsStatus
  };
};

const modelPatientProfile = (props = {}) => {
  const {
    medicationsStore = {},
    destinationStore,
    fullPatientProfile = false,
    instanceLink = {},
    profile = {},
    surveyOptions = [],
    surveyResponses = []
  } = props;

  const allMedications = getAllMedications({ medicationsStore });
  const allMedicationsStatus = getAllMedicationsStatus({ medicationsStore });
  const {
    currentMedications = [],
    emptyMedicationDropdownsAnswered,
    yellowZoneOption
  } = medicationsStore || {};

  const { instanceLinkUUID, profileUUID, recordChangeDateTime } =
    instanceLink || {};
  const { firstName, lastName, propertyLinks } = profile || {};
  const {
    lastUpdatedBy = '',
    lastUpdatedTimestamp = recordChangeDateTime
  } = chain(propertyLinks)
    .reduce((acc, curr) => {
      const { propertyKey, propertyValue } = curr;
      if (propertyKey === 'lastUpdatedTimestamp') {
        return Object.assign({}, acc, {
          lastUpdatedTimestamp: propertyValue ? parseInt(propertyValue, 10) : ''
        });
      }

      if (propertyKey === 'lastUpdatedBy') {
        return Object.assign({}, acc, {
          lastUpdatedBy: propertyValue || ''
        });
      }

      return acc;
    }, {})
    // .thru(obj => {
    //   const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
    //   const todaysDateTime = new Date().getTime();

    //   return {
    //     lastUpdatedBy: obj.lastUpdatedBy,
    //     lastUpdatedTimestamp: todaysDateTime - ONE_WEEK * 5
    //   };
    // })
    .value();

  const targetSurveyResponses = filter(surveyResponses, { instanceLinkUUID });

  const cdssStatuses = getCDSSStatus({ instanceLink }) || {};
  const cdssStatusesResponses =
    getCDSSStatusResponses({
      allMedicationsStatus,
      surveyResponses: targetSurveyResponses
    }) || {};

  const questionnaireCompleted = getQuestionnaireCompleted({
    surveyResponses: targetSurveyResponses
  });

  const questionnaireCompletedDateTime = getQuestionnaireCompletedDateTime({
    surveyResponses: targetSurveyResponses
  });

  const basicPatientProfile = Object.assign(
    {
      firstName,
      instanceLinkUUID,
      lastName,
      lastUpdated: lastUpdatedTimestamp,
      lastUpdatedBy,
      profileUUID,
      questionnaireCompleted,
      questionnaireCompletedDateTime
    },
    cdssStatuses,
    cdssStatusesResponses
  );

  if (!fullPatientProfile) {
    return basicPatientProfile;
  }

  const modeledQuestionnaireResponses =
    modelQuestionnaireResponses({
      surveyOptions,
      surveyResponses: targetSurveyResponses
    }) || {};
  const {
    controlResponses,
    controlSurveyResponses
  } = modeledQuestionnaireResponses;

  const controlQuestionsCompleted = isControlQuestionsCompleted({
    controlResponses
  });

  const lastControlQuestionsCompletedDateTime = getLastControlQuestionsCompletedDateTime(
    {
      controlSurveyResponses
    }
  );

  const prependChartNoteMessagesResponse = getPrependChartNoteMessagesResponse({
    surveyResponses: targetSurveyResponses
  });

  const smoke = chain(targetSurveyResponses)
    .filter({ surveyLinkUUID: '17258242-6BBD-40DC-8BBA-E2A48BA8E73D' })
    .map('responseOptionUUID')
    .head()
    .thru(value => {
      if (value === OPTION_UUID_YES) {
        return 'yes';
      }

      if (value === OPTION_UUID_NO) {
        return 'no';
      }

      return '';
    })
    .value();

  const flarePillsVisit = chain(targetSurveyResponses)
    .filter({ surveyLinkUUID: '99F46447-097D-4AE9-BD23-059966272AAA' })
    .map('responseOptionUUID')
    .head()
    .thru(value => {
      if (value === OPTION_UUID_YES) {
        return 'yes';
      }

      if (value === OPTION_UUID_NO) {
        return 'no';
      }

      return '';
    })
    .value();

  const recommendationsAvailable = chain({
    controlResponses
  })
    .thru(obj => {
      if (Object.values(obj.controlResponses).length === 6) {
        return true;
      }

      let controller = false;
      forEach(currentMedications, med => {
        const { functionType } = med;
        if (functionType === 'controller') {
          controller = true;
        }
      });

      if (
        obj.controlResponses.woken === 'yes' ||
        obj.controlResponses.symptoms === 'about 3' ||
        obj.controlResponses.symptoms === '4 or more' ||
        obj.controlResponses.rescue === 'about 3 times per week' ||
        obj.controlResponses.rescue === '4 or more times per week' ||
        obj.controlResponses.schoolwork === 'yes' ||
        obj.controlResponses.exercise === 'yes' ||
        obj.controlResponses.flare === 'yes' ||
        smoke === 'yes' ||
        flarePillsVisit === 'yes' ||
        controller
      ) {
        return true;
      }

      return false;
    })
    .value();

  const medications = currentMedications;

  const isDestinationStoreEmptyResult = isDestinationStoreEmpty({
    destinationStore,
    responses: targetSurveyResponses
  });
  const defaultDestinationStore = DEFAULT_DESTINATION_STORES[destinationStore];

  const ruleTag = chain(targetSurveyResponses)
    .find(surveyResponse => {
      const {
        questionId,
        responseCollectionUUID,
        responseSetId
      } = surveyResponse;

      return (
        questionId === 'meds_tag' &&
        responseCollectionUUID &&
        responseSetId === destinationStore
      );
    })
    .thru(surveyResponse => {
      if (surveyResponse) {
        return surveyResponse.responseString;
      }

      return '';
    })
    .value();

  const ruleTagYellow = chain(targetSurveyResponses)
    .filter(surveyResponse => {
      const { questionId, responseSetId } = surveyResponse;

      return (
        questionId === 'meds_yellow_tag' && responseSetId === destinationStore
      );
    })
    .map(surveyResponse => {
      if (surveyResponse) {
        return surveyResponse.responseString;
      }

      return '';
    })
    .value();

  const defaultMedications = allMedications[defaultDestinationStore];

  const medicationCalculatedProperties =
    getPatientProfileMedicationCalculatedProperties({
      controlResponses,
      medications
    }) || {};

  return Object.assign(basicPatientProfile, modeledQuestionnaireResponses, {
    allMedications,
    controlResponses,
    controlQuestionsCompleted,
    defaultDestinationStore,
    defaultMedications,
    emptyMedicationDropdownsAnswered,
    flarePillsVisit,
    isDestinationStoreEmpty: isDestinationStoreEmptyResult,
    ...medicationCalculatedProperties,
    lastControlQuestionsCompletedDateTime,
    medications,
    prependChartNoteMessagesResponse,
    recommendationsAvailable,
    ruleTag,
    ruleTagYellow,
    smoke,
    yellowZoneOption
  });
};

export default modelPatientProfile;
